@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.input {
  @include column;
  width: 100%;
  height: auto;

  &__errors {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1875rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 5px;

    &[data-error="true"] {
      color: $col-fail;
      & svg {
        // width: 16px;
        path {
          fill: $col-fail;
        }
      }
    }

    &[data-success="true"] {
      color: $col-secondary-10;
      & svg {
        path {
          fill: $col-secondary-10;
        }
      }
    }
    &-message {
      // margin-left: 10px;
      margin-left: 0px;
    }
  }

  & label {
    margin-bottom: 4px;
    color: $col-black;
    font-size: 16px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__tel {
    @include flex-row;
    border-right: 1px solid $col-secondary-7;
    padding: 0 1.5rem;
    line-height: 1.3638rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    color: $col-secondary-9;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  &__field {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
    // height: 50px;

    &[data-input="true"] {
      position: relative;
      background-color: $col-secondary-2;

      @include border-radius(2px);
      @include row;
    }

    & input,
    & textarea {
      width: 100%;
      height: 100%;
      outline: 0;
      padding: 0.8rem 1rem;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding-right: 3rem;
      border: 1px solid $col-secondary;
      background-color: $col-secondary-2;

      @include border-radius(8px);
      @include flex-space-between;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $col-secondary-9;
      }

      &:focus {
        outline: 1px solid $col-primary;
      }
    }
    & textarea {
      padding: 10px;
      resize: none;
    }
  }
}

.search-input {
  width: 100%;
  height: 46px;
  background-color: transparent;
  position: relative;

  svg {
    position: absolute;
    top: 15px;
    left: 15px;
    transform: translate(-12%, -12%);
  }

  input {
    height: 100%;
    width: 100%;
    min-height: 46px;
    border: none;
    @include border-radius(4px);
    padding-left: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    background: $col-secondary-2;
    border: 1px solid $col-secondary-7;

    @include transition;

    &:hover {
      border: 1px solid $col-secondary-12;
    }

    &:focus {
      border: 1px solid $col-secondary-12;
      outline: 2.5px solid $col-secondary-5;
      outline-offset: 0.4px;
    }

    &::placeholder {
      color: $col-secondary-3;
      font-size: 14px;
    }
  }
}
