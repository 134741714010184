@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.EventPartCard {
  width: 100%;
  // min-height: 255px;
  border: 1px solid $col-secondary;
  background-color: $col-white;
  box-shadow: 0px 1px 1px rgba(224, 224, 224, 0.25);
  padding: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;

    &--title {
      font-size: 18px;
      font-weight: 600;
    }
    &--icon {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-action {
      background-color: $col-white;
      width: 138px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding: 1rem;
      right: 20px;
      top: 0;
      box-shadow:
        0px 0px 2px rgba(125, 125, 125, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      p {
        cursor: pointer;
        &:first-child {
          margin-bottom: 10px;
        }
        &:last-child {
          color: $col-fail;
        }
      }
    }
  }

  &__main {
    ul {
      li {
        display: grid;
        grid-template-columns: 1fr 11fr;
        align-items: center;
        margin-bottom: 1rem;
        p {
          margin-left: 14px;
        }
      }
    }
  }

  &__dropdown {
    &-container {
      position: relative;
      &-button {
        background-color: $col-white;
        @include border-radius(4px);
        @include row;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: $col-secondary;
        }
      }
    }

    &-list {
      @include grid-row(auto-fill, auto);
      padding: 0.5rem;

      & button {
        text-align: left;
      }
    }

    @include floating-transition;

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }

  &__remove {
    background-color: $col-white;
    padding: 0.5rem 1rem;
    color: rgb(247, 49, 49);
    border-radius: 4px;
    font-size: 1rem;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }

  &__edit {
    padding: 0.5rem 1rem;
    background-color: $col-white;
    border-radius: 4px;
    font-size: 1rem;
    color: $col-black;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }
}
