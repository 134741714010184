@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.guestlist__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    color: #00000099;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 2.24px;
    text-transform: uppercase;
  }

  .guest__cards__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.guest__card__container {
  max-width: 640px;
  padding: 12px 16px;
  border: 0.5px solid #e4e4e7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 40px;
    height: 45px;
    border-radius: 7.3px;
    border: 0.4px solid #a4a2a2;
    object-fit: cover;
  }

  .guest__avatar {
    color: #ffffff;
    width: 40px;
    height: 45px;
    border-radius: 7.3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background-image: linear-gradient(to bottom right, #37645d, #1f5c5361);
    text-transform: uppercase;
  }

  .guest__info__container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .first__row {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        letter-spacing: 0px;
        text-transform: capitalize;
      }
    }

    .second__row {
      display: flex;
      align-items: center;
      gap: 8px;

      @include respond(phone) {
        flex-wrap: wrap;
      }

      p {
        font-size: 14px;
        color: #71717a;
      }
    }
  }
}

.guest__card__container:hover {
  border: 0.7px solid #4e7e76;
  background-image: linear-gradient(to bottom right, #f7fdfc, #f7fdfc00);
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #71717a;
}
