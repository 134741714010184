.qrCodeContainer {
  width: 100px;
  height: 100px;
  background-color: rgba(230, 250, 82, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(61, 81, 96, 0.5);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  .qrcode {
    width: 100%;
    height: 100%;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
