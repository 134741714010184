@use "../../styles/abstracts/variables.scss" as *;
@use "../../styles/abstracts/mixins" as *;

.input {
  @include column;
  width: 100%;
  height: auto;

  &__errors {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1875rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 5px;

    &[data-error="true"] {
      color: $col-fail;
      & svg {
        // width: 16px;
        path {
          fill: $col-fail;
        }
      }
    }

    &[data-success="true"] {
      color: $col-secondary-10;
      & svg {
        path {
          fill: $col-secondary-10;
        }
      }
    }
    &-message {
      margin-left: 10px;
    }
  }

  & label {
    margin-bottom: 4px;
    color: $col-black;
    font-size: 16px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__tel {
    @include flex-row;
    // border-right: 1px solid $col-secondary-7;
    border-right: 1px solid red !important;
    padding: 0 1.5rem;
    line-height: 1.3638rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    color: $col-secondary-9;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  &__field {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
    height: 50px;

    &[data-input="true"] {
      position: relative;
      background-color: $col-secondary-2;

      @include border-radius(8px);
      @include row;
    }

    &[data-focused="true"] {
      border-bottom: 1px solid $col-primary;
      border-radius: 0px 0px 2px 2px;
    }

    @include respond(big-tab) {
      height: 48px;
    }

    .flag-dropdown {
      border: none !important;
      border-right: 1px solid $col-secondary-7 !important;
      background: transparent !important;

      & > div {
        background: transparent !important;
        border: none !important;
      }
    }

    // & input,
    .form-control {
      width: 100%;
      height: 100%;
      outline: 0;
      border: none !important;
      background-color: $col-secondary-2;
      border-radius: 8px !important;
      overflow: hidden;
      border: 1px solid $col-secondary-7 !important;
    }
  }
}
