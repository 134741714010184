@use "../../../../styles/abstracts/variables" as *;
@use "../../../../styles/abstracts/mixins" as *;

.addBackstory {
  background-color: $col-white;
  padding: 40px;
  height: auto;
  width: 551px;
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-row-gap: 16px;
  border-radius: 5px;

  @include respond(phone) {
    border-radius: 0px;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: calc(100vh - calc(100vh - 100%));
    width: 100vw;
    width: -moz-available;
    width: -webkit-fill-available;
    @include z-index(fill-page);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-heading {
    & button {
      display: none;
      @include respond(phone) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    & h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.0625rem;
      text-align: left;
    }
    & p {
      @include respond(phone) {
        display: none;
      }
    }
  }
  &-form {
    &-select {
      @include column;
      & label {
        margin-bottom: 8px;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        text-align: left;
        color: $col-black;
      }
      /* class applies to select element itself, not a wrapper element */
      select {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: $col-black;
        background-color: #fafafa;
        border: 1px solid #f2f2f2;
        border-radius: 2px;
        @include row;
        font-family: $font;
        padding: 0px 24px;
        width: 100%;
        height: 50px;
        max-width: 100%; /* useful when width is set to anything other than 100% */
        margin: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat, repeat;
        /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
        background-position:
          right 0.7em top 50%,
          0 0;
        /* icon size, then gradient */
        background-size:
          0.65em auto,
          100%;
      }
      /* Hide arrow icon in IE browsers */
      &::-ms-expand {
        display: none;
      }
      /* Hover style */
      &:hover {
        border: none;
      }
      /* Focus style */
      .select-css:focus {
        border-bottom: 1px solid $col-primary;
        color: #000;
        outline: none;
      }

      /* Set options to normal weight */
      & option {
        font-weight: normal;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #202223;
      }

      /* Disabled styles */
      &:disabled,
      &[aria-disabled="true"] {
        color: gray;
      }

      &:disabled:hover,
      &[aria-disabled="true"] {
        border-color: #aaa;
      }
    }

    &-textarea {
      height: 168px;
      & div {
        height: 100% !important;
      }
    }
    &-container {
      @include grid-row(auto-fill, auto);
      grid-row-gap: 24px;
    }
  }

  &-image {
    @include grid-row(2, auto);
    grid-row-gap: 8px;

    &-box {
      width: 100%;
      height: 150px;
      background: #fafafa;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
      position: relative;

      @include flex-row;
      & div {
        width: 70px;
        height: 70px;
      }
    }
  }

  &-file-picker {
    &-text {
      margin-bottom: 8px;
    }
    &-box {
      width: 100%;
      height: 149px;
      background-color: $col-secondary-2;
      border: 1px solid $col-secondary-2;
      @include flex-row;
    }
  }
  &-button {
    margin-top: 40px;
    width: 165px;
    &-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.file {
  // width: 0.1px;
  // height: 0.1px;
  // opacity: 0;
  // overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  // z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(150, 225, 150, 0.5);
  vertical-align: middle;
  text-align: center;
  padding: 0 20%;
  color: #fff;
  font-weight: bold;
  font-size: 400px;
  opacity: 0;
  cursor: pointer;

  & + label {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #bfbfbf;
  }
}

.file + label {
  cursor: pointer;
}
