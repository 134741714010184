@use "../../../styles/abstracts/mixins" as *;
@use "../../../styles/abstracts/variables" as *;

.planner {
  &__card {
    width: fit-content;
    @include flex-row;
    justify-content: flex-start;
    padding: 16px 40px;
    background: $col-secondary-2;
    margin-bottom: 0.75rem;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $col-black;
      width: 180px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      text-align: left;
    }
  }

  &__dropdown {
    &__icon {
      width: 32px;
      height: 32px;
      display: grid;
      place-items: center;
      cursor: pointer;
      border-radius: 4px;

      @include transition;

      &:hover {
        background-color: $col-secondary;
      }
    }

    &__content {
      background: $col-white;
      box-shadow:
        0px 0px 2px rgba(125, 125, 125, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 4px;
      @include column;

      @include floating-transition;
    }

    &__item {
      color: #e61919;
      padding: 8px;
      cursor: pointer;

      &:hover {
        background-color: $col-secondary;
      }
    }
  }

  &__remove {
    font-size: 16px;
    background-color: $col-white;
    padding: 0.5rem 1rem;
    @include border-radius(4px);
    text-align: left;
    color: rgb(247, 49, 49);
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }

  &__edit {
    padding: 0.5rem 1rem;
    font-size: 16px;
    @include border-radius(4px);
    background-color: $col-white;
    text-align: left;
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }
}
