@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @include respond(phone) {
    gap: 20px;
  }

  .info__btns__container {
    display: flex;
    align-items: center;
    gap: 15px;

    .guest__count {
      display: flex;
      align-items: center;
      border: 1.4px solid #e4e4e7;
      padding: 9px 11px;
      border-radius: 12px;
      gap: 11px;
    }

    .search__btn {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 12px;
      border-radius: 12px;
      border: 1.4px solid #e4e4e7;
    }
  }

  .action__btns__container {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    @include respond(phone) {
      gap: 12px;
    }

    .btn {
      border: 1px solid #66666666;
      border-radius: 9px;
      padding: 10.6px 22.5px;
      font-weight: 500;
      font-size: 13.9px;
    }
  }
}
