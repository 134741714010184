@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.container {
  margin-top: 30px;
  max-width: 500px;
  margin: auto;
  padding: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  .header__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 7px;

    h3 {
      color: #298576;
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0.392px;

      @include respond(phone) {
        font-size: 16px;
      }
    }

    p {
      color: #707070;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      @include respond(phone) {
        font-size: 15px;
      }
    }
  }

  h3 {
    color: #a7a7a7;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.04px;
    text-transform: uppercase;

    .actual__price {
      color: rgba(112, 112, 112, 0.6);
      font-size: 20.73px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      text-decoration: line-through;
    }

    .discount__price {
      color: #4c4a4a;
      font-size: 27.73px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @include respond(phone) {
      .actual__price {
        font-size: 22px;
      }

      .discount__price {
        font-size: 22px;
      }
    }
  }

  p {
    color: #a7a7a7;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .features__container {
    display: flex;
    flex-direction: column;
    gap: 23px;
    align-items: center;
    max-width: 350px;
    margin: auto;

    .feature {
      display: flex;
      gap: 14px;
      align-items: center;
      justify-content: center;

      p {
        color: #4e4e4e;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-transform: none;

        span {
          color: rgba(58, 87, 82, 0.5);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        @include respond(phone) {
          font-size: 15px;

          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}
