@use "src/styles/abstracts/mixins" as *;
@use "src/styles/abstracts/variables" as *;

.messagesTable {
  width: fit-content;
  // min-width: fit-content;
  // width: 100%;
  @include flex-row;
  justify-content: flex-start;
  padding: 16px 40px;
  background: $col-secondary-2;
  gap: 0.75rem;
  margin-bottom: 0.5rem;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $col-black;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: left;
  }

  &__dropdown {
    position: relative;
    @include floating-transition;

    &-container {
      &-button {
        background-color: $col-secondary-2;
        @include border-radius(4px);
        @include row;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        color: $col-black;
        font-weight: 600;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.1875rem;

        &:hover,
        &:focus,
        &:focus-visible {
          background-color: $col-secondary;
        }
      }
    }
    &-list {
      box-shadow:
        0px 0px 2px rgba(125, 125, 125, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      background-color: $col-white;
      flex-direction: column;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;
      padding: 0.5rem;
      display: flex;
      @include border-radius(4px);
      @include z-index(dropdown-menu);
      box-shadow:
        0px 0px 2px rgba(125, 125, 125, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      @include floating-transition;

      &-edit {
        padding: 0.5rem 1rem;
        cursor: pointer;
        @include transition;
        border-radius: 4px;
        &:hover,
        &:focus {
          background-color: $col-secondary-32;
        }
      }

      &-delete {
        padding: 0.5rem 1rem;
        cursor: pointer;
        color: #e61919;
        border-radius: 4px;
        @include transition;

        &:hover,
        &:focus {
          background-color: $col-secondary-32;
        }
      }
    }
  }

  &__name,
  &__number,
  &__time {
    & p {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: left;
      color: $col-black;
      overflow-x: hidden;
      white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__reply {
    height: 36px;
    @include flex-row;
    width: 100px;

    &__btn {
      width: 100%;
      height: 100%;
      @include flex-row;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
      padding: 8px 16px;
      @include border-radius(3px);
      color: #141414;
      border: 1px solid #141414;
    }
  }
}
