@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.liveHall {
  overflow-y: auto;
  width: fit-content;
  min-width: 100%;
  position: relative;
  padding: 0.6rem;
  margin: 0 auto;

  @include respond(mini-laptop) {
    width: 100%;
  }

  &__loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__trigger {
    width: 100%;
    height: 46px;
    background-color: $col-primary;
    padding: 0px 24px;
    box-shadow: inset 0px -6px 10px #314f4a;
    @include flex-row;
    @include border-radius(4px);
    @include transition;

    & span {
      color: $col-white !important;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &:focus {
      outline: 1px dotted transparent;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:focus-visible {
      outline: 1px dotted $col-secondary-8;
      outline-offset: 1px;

      & span {
        color: $col-white;
      }
    }

    &:disabled,
    &[disabled] {
      @include border-radius(4px);
      background-color: $col-secondary-8;
      box-shadow: none;
      filter: none;

      &:hover,
      &:focus-within {
        background-color: $col-secondary-8;
        cursor: not-allowed;

        & span {
          color: $col-white;
        }
      }
    }

    &:hover,
    &:focus-within {
      background-color: $col-secondary-4;

      & span {
        color: $col-white;
      }
    }

    &:active {
      background-color: $col-primary;
    }
  }
  &__table {
    @include column;

    &-header {
      @include flex-row;
      justify-content: flex-start;
      padding: 16px 40px;
      width: 100%;
      height: 45px;
      align-items: center;
      gap: 0.75rem;

      &__end {
        min-width: auto !important;
      }

      & h4 {
        font-style: normal;
        flex: 1;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        min-width: 180px;
        color: #808080;
      }
    }
  }

  &__none {
    display: grid;
    place-items: center;
    width: 100%;
    text-align: center;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.75rem;
    width: 100%;
    padding: 0.3rem;
    flex-wrap: wrap;

    @include respond(tab) {
      padding: 0.1rem 0rem;
    }

    &--search {
      width: 300px;

      &-container {
        @include row;
        align-items: center;
        // width: 100%;
        gap: 0.75rem;
        flex-wrap: wrap;

        @include respond(tab) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &__icon {
      border: 1px solid #e4e4e7;
      padding: 8px 16px;
    }

    &--dropdown {
      background: "#fff8e6";
      border: "0.5px dashed #ffb800";
      display: "flex";
      flex-direction: "row";
      align-items: "center";
      justify-content: "center";
      padding: "12px 8px";
      text-align: "center";
      color: "#996e00";
      border-radius: "0px";
    }
    &__button {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
    &__buttons {
      width: 10rem;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      cursor: pointer;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      @include respond(phone) {
        width: auto;
      }
      & span {
        color: $col-white;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline-offset: 1px;
        outline: 1px dotted transparent;
      }

      &:focus-visible {
        outline-offset: 1px;
        outline: 1px dotted $col-secondary-8;
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;
      }

      &:active {
        background-color: $col-primary;
      }
    }
    &__resendbutton {
      width: 5rem;
      height: 46px;
      background-color: $col-white;
      padding: 0px 24px;
      border: 1px solid #bfbfbf;
      box-sizing: border-box;
      border-radius: 4px;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-black;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline-offset: 1px;
        outline: 1px dotted transparent;
      }

      &:focus-visible {
        outline-offset: 1px;
        outline: 1px dotted $col-secondary-8;
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;
        }
      }
    }

    &--btn {
      width: auto;

      &__send {
        position: relative;

        svg {
          position: absolute;
          top: 50%;
          right: 7.5%;
          transform: translate(-50%, -50%);
        }

        select {
          border: 1px solid $col-black;
          color: $col-black;
          background-color: $col-white;
          padding: 11px 32px 11px 16px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          border-radius: 4px;
          cursor: pointer;

          // @include flex-row;
          // gap: 0.75rem;

          @include transition;

          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &::-ms-expand {
            display: none;
          }

          // &:hover {
          //   background-color: $col-secondary-7;
          // }

          &:focus {
            outline: 1px dotted transparent;
            outline-offset: 1px;
          }

          &:focus-visible {
            outline: 1px dotted $col-black;
            outline-offset: 1px;
          }
        }
      }

      .actionlist {
        background-color: $col-white;
        position: absolute;
        height: auto;
        width: 138px;
        @include border-radius(4px);
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.2),
          0px 2px 10px rgba(0, 0, 0, 0.1);

        li {
          cursor: pointer;
          padding: 0.7rem 1rem;
          &:hover {
            background-color: $col-secondary-2;
          }
        }
      }
    }

    @include respond(phone) {
      &--search {
        width: 100%;
      }
      &--btn {
        margin-left: 0;
      }
    }
  }

  &__main {
    margin-top: 2rem;
    width: 100%;
    overflow-x: auto;

    @include respond(phone) {
      // padding: 0 1.2rem;
    }

    &--pagination {
      padding: 10px;
      background-color: $col-secondary;
      min-height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13rem;
      width: 100%;
      font-size: 12px;
      position: absolute;
      top: 100%;
      left: 0;

      @include respond(mini-laptop) {
        padding-right: 6rem;
      }

      @include respond(tab) {
        padding-right: 1rem;
      }

      @include respond(phone) {
        padding-right: 10px;
      }

      section {
        font-weight: 600;
        @include flex-row;
        &:first-child {
          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        &:last-child {
          @include flex-row;
          margin-left: 4rem;
          padding-top: 5px;
          height: auto;
          flex-wrap: wrap;
          @include respond(phone) {
            justify-content: flex-end;
          }

          div {
            margin-left: 1rem;
            cursor: pointer;
            .rotate {
              margin-left: 50px;
              svg {
                transform: rotate(180deg);
              }
            }
            @include respond(phone) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }
}

.search-input {
  width: 100%;
  height: 46px;
  background-color: transparent;
  position: relative;

  svg {
    position: absolute;
    top: 15px;
    left: 15px;
    transform: translate(-12%, -12%);
  }

  input {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: none;
    padding-left: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    background: $col-secondary-2;
    border: 1px solid $col-secondary-7;

    @include transition;

    &:hover {
      border: 1px solid $col-secondary-12;
    }

    &:focus {
      border: 1px solid $col-secondary-12;
      outline: 2.5px solid $col-secondary-5;
      outline-offset: 0.5px;
    }

    &::placeholder {
      color: $col-secondary-3;
      font-size: 14px;
    }
  }
}
