@use "src/styles/abstracts/variables.scss" as *;
@use "src/styles/abstracts/mixins" as *;

.invite-list {
  overflow-y: auto;
  // width: fit-content;
  position: relative;

  @include respond(mini-laptop) {
    width: 100%;
  }

  &__dropdown {
    &-container {
      position: relative;
      &-button {
        width: 100%;
        height: 46px;
        background-color: $col-primary;
        padding: 0px 24px;
        box-shadow: inset 0px -6px 10px #314f4a;
        @include flex-row;
        @include border-radius(4px);
        @include transition;

        & > div {
          color: $col-white;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.375rem;
          @include flex-space-between;
          gap: 0.75rem;
          white-space: nowrap;
        }

        &:focus {
          outline: 1px dotted transparent;
          outline-offset: 1px;
        }

        &:focus-visible {
          outline: 1px dotted $col-secondary-8;
          outline-offset: 1px;
        }

        &:hover,
        &:focus-within {
          background-color: $col-secondary-4;
        }

        &:active {
          background-color: $col-primary;
        }
      }
    }

    &-list {
      @include grid-row(auto-fill, auto);
      padding: 0.5rem;

      & button {
        text-align: left;
      }
    }

    @include floating-transition;

    @include z-index("dropdown-menu");
    background-color: $col-white;
    box-shadow: 1px 2px 6px rgba(51, 51, 51, 0.15);
    @include border-radius(4px);
    width: fit-content;
    white-space: nowrap;
    height: auto;
    margin-top: 10px;
    &[aria-expanded="true"] {
      display: block;
    }
  }

  &__edit {
    padding: 0.5rem 1.5rem;
    @include border-radius(4px);
    background-color: $col-white;
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $col-secondary;
    }
  }

  &__table {
    @include column;

    &-header {
      @include flex-row;
      justify-content: flex-start;
      padding: 16px 40px;
      width: 100%;
      height: 45px;
      align-items: center;
      gap: 0.75rem;

      & h4 {
        font-style: normal;
        text-align: left;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        min-width: 180px;
        color: #808080;
      }
    }
  }

  &__none {
    display: grid;
    place-items: center;
    width: 100%;
    text-align: center;
  }

  &__header {
    @include flex-space-between;
    align-items: flex-start;
    gap: 0.75rem;
    width: 100%;
    padding: 0.3rem;
    flex-wrap: wrap;

    @include respond(tab) {
      padding: 0.1rem 1.2rem;
      flex-direction: column;
      align-items: flex-start;
    }

    &--search {
      width: 300px;

      &-container {
        @include row;
        align-items: center;
        // width: 100%;
        gap: 0.75rem;
        flex-wrap: wrap;

        @include respond(tab) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &__icon {
      border: 1px solid #e4e4e7;
      padding: 8px 16px;
    }

    &--dropdown {
      background: "#fff8e6";
      border: "0.5px dashed #ffb800";
      display: "flex";
      flex-direction: "row";
      align-items: "center";
      justify-content: "center";
      padding: "12px 8px";
      text-align: "center";
      color: "#996e00";
      border-radius: "0px";
    }
    &__button {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }
    &__buttons {
      width: 10rem;
      height: 46px;
      background-color: $col-primary;
      padding: 0px 24px;
      cursor: pointer;
      box-shadow: inset 0px -6px 10px #314f4a;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      @include respond(phone) {
        width: auto;
      }
      & span {
        color: $col-white;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline-offset: 1px;
        outline: 1px dotted transparent;
      }

      &:focus-visible {
        outline-offset: 1px;
        outline: 1px dotted $col-secondary-8;
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;
        }
      }

      &:hover,
      &:focus-within {
        background-color: $col-secondary-4;
      }

      &:active {
        background-color: $col-primary;
      }
    }
    &__resendbutton {
      width: 5rem;
      height: 46px;
      background-color: $col-white;
      padding: 0px 24px;
      border: 1px solid #bfbfbf;
      box-sizing: border-box;
      border-radius: 4px;
      @include flex-row;
      @include border-radius(4px);
      @include transition;

      & span {
        color: $col-black;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &:focus {
        outline-offset: 1px;
        outline: 1px dotted transparent;
      }

      &:focus-visible {
        outline-offset: 1px;
        outline: 1px dotted $col-secondary-8;
      }

      &:disabled,
      &[disabled] {
        @include border-radius(4px);
        background-color: $col-secondary-8;
        box-shadow: none;
        filter: none;

        &:hover,
        &:focus-within {
          background-color: $col-secondary-8;
          cursor: not-allowed;
        }
      }
    }

    &--btn {
      width: auto;

      &__send {
        position: relative;

        svg {
          position: absolute;
          top: 50%;
          right: 7.5%;
          transform: translate(-50%, -50%);
        }

        select {
          border: 1px solid $col-black;
          color: $col-black;
          background-color: $col-white;
          padding: 11px 32px 11px 16px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          border-radius: 4px;
          cursor: pointer;

          // @include flex-row;
          // gap: 0.75rem;

          @include transition;

          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &::-ms-expand {
            display: none;
          }

          // &:hover {
          //   background-color: $col-secondary-7;
          // }

          &:focus {
            outline: 1px dotted transparent;
            outline-offset: 1px;
          }

          &:focus-visible {
            outline: 1px dotted $col-black;
            outline-offset: 1px;
          }
        }
      }

      .actionlist {
        background-color: $col-white;
        position: absolute;
        height: auto;
        width: 138px;
        @include border-radius(4px);
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.2),
          0px 2px 10px rgba(0, 0, 0, 0.1);

        li {
          cursor: pointer;
          padding: 0.7rem 1rem;
          &:hover {
            background-color: $col-secondary-2;
          }
        }
      }
    }

    @include respond(phone) {
      &--search {
        width: 100%;
      }
      &--btn {
        margin-left: 0;
      }
    }
  }

  &__main {
    margin-top: 2rem;
    width: 100%;
    overflow-x: auto;

    @include respond(phone) {
      padding: 0 1.2rem;
    }

    &--pagination {
      padding: 10px;
      background-color: $col-secondary;
      min-height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13rem;
      width: 100%;
      font-size: 12px;
      position: absolute;
      top: 100%;
      left: 0;

      @include respond(mini-laptop) {
        padding-right: 6rem;
      }

      @include respond(tab) {
        padding-right: 1rem;
      }

      @include respond(phone) {
        padding-right: 10px;
      }

      section {
        font-weight: 600;
        @include flex-row;
        &:first-child {
          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        &:last-child {
          @include flex-row;
          margin-left: 4rem;
          padding-top: 5px;
          height: auto;
          flex-wrap: wrap;
          @include respond(phone) {
            justify-content: flex-end;
          }

          div {
            margin-left: 1rem;
            cursor: pointer;
            .rotate {
              margin-left: 50px;
              svg {
                transform: rotate(180deg);
              }
            }
            @include respond(phone) {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  &__loading {
    height: 50vh;
    width: 100%;
    @include flex-row;
  }
}

.count {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.4rem;
  gap: 8px;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #141414;
}

.search-input {
  width: 100%;
  height: 46px;
  background-color: transparent;
  position: relative;

  svg {
    position: absolute;
    top: 15px;
    left: 15px;
    transform: translate(-12%, -12%);
  }

  input {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: none;
    padding-left: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    background: $col-secondary-2;
    border: 1px solid $col-secondary-7;

    @include transition;

    &:hover {
      border: 1px solid $col-secondary-12;
    }

    &:focus {
      border: 1px solid $col-secondary-12;
      outline: 2.5px solid $col-secondary-5;
      outline-offset: 0.5px;
    }

    &::placeholder {
      color: $col-secondary-3;
      font-size: 14px;
    }
  }
}
